<template>
  <!-- 注册成功 -->
  <div id="house-login" class="layui-fulid findpassword">
    <div class="layui-form">
      <div class="">
        <img class="icon" src="@/assets/center/cross.png" />
        <h4>请先用邮箱注册账号</br>然后进行微信绑定</h4>
      </div>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
    };
  },
  mounted() {
    this.userVerifycation();
  },
  methods: {
    goMainPage() {
      window.location.href = "/";
    },
  },
};
</script>
<!-- Event snippet for 注册账号 conversion page -->
<script>
gtag('event', 'conversion', {'send_to': 'AW-316705807/8MRqCPrqxO4CEI-YgpcB'});
</script>

<style>
</style>
